.skip-links-container {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 2;
    @media (max-width: 700px) {
        line-height: 50px;
    }
}

.skip-links {
    display: inline-block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    &:focus {
        width: auto;
        height: auto;
        padding: 5px;
        color: $white;
        background: $header;
    }
}

ul.social-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    @media(max-width: 700px) {
        justify-content: center;
    }
    li {
        margin-right: 13px;
        @media(max-width: 700px) {
            margin-right: 10px;
        }
        &:last-child {
            margin-right: 0px;
        }
    }
    a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    svg {
        width: 23px;
        fill: $headerIcons;
        &.short-icon {
            width: 33px;
        }
        &.newsletter-icon {
            width: 27px;
        }
    }
    a:hover svg,
    a:focus svg {
        fill: $headerIconsHover;
    }
}

ul.main-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    li {
        margin-left: 10px;
    }
}

.main-header {
    background: $header;
    padding: 5px 0;
}