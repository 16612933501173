    * {
        box-sizing: border-box;
    }
    
    html {
        font-size: 62.5%;
    }
    
    body {
        font-size: 16px;
        font-size: 1.6rem;
        font-family: Arial, Helvetica, sans-serif;
        background-color: $background;
        color: $white;
        background-image: url(../../images/tedy-footer-glass.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 648px;
        @media(max-width: 700px) {
            background-position: bottom left;
            background-image: url(../../images/tedy-footer-glass-mobile.png);
            background-size: 100%;
        }
    }
    
    img,
    svg {
        max-width: 100%;
        height: auto;
    }
    
    iframe {
        max-width: 100%;
    }
    
    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        width: 1px;
        word-wrap: normal !important;
    }
    
    .container {
        width: 1000px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .youtube-embed {
        max-height: 56vw;
    }
    
    main {
        background-image: url(../../images/tedy-war-hero-c.jpg);
        background-size: 100%;
        background-position: top;
        background-repeat: no-repeat;
        @media (max-width: 700px) {
            background-image: url(../../images/tedy-war-hero-mobile.jpg);
        }
    }
    
    .hero {
        min-height: 81vw;
        padding-top: 39vw;
        @media(max-width: 700px) {
            min-height: 212vw;
            padding-top: 136vw;
            padding-bottom: 2px;
        }
    }
    
    .hero-link {
        margin: 0 0px 0 auto;
        display: block;
        width: 290px;
        &:hover,
        &:focus {
            .listen-now-svg {
                fill: $headerIconsHover;
            }
        }
        @media(max-width: 1300px) {
            margin: 0 30px 0 auto;
        }
        @media(max-width: 800px) {
            margin: 0 0px 0 auto;
        }
        @media(max-width: 700px) {
            margin: 0 auto 25px;
            width: 240px;
        }
    }
    
    .tedy-war-mobile {
        display: none;
    }
    
    @media(max-width: 700px) {
        .tedy-war-desktop {
            display: none;
        }
        .tedy-war-mobile {
            display: block;
        }
    }
    
    .listen-now-svg {
        fill: $white;
        width: 150px;
        margin: -25px 7px 0 auto;
        display: block;
        @media(max-width: 700px) {
            margin: 0 auto;
            width: 160px;
        }
    }
    
    .tour-dates-heading {
        background-image: url(../../images/tour-dates-background.jpg);
        background-size: cover;
        background-position: top right;
        background-repeat: no-repeat;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        border: 1px solid white;
        @media(max-width: 700px) {
            padding-left: 0;
            justify-content: center;
        }
        @media(max-width: 500px) {
            background-image: url(../../images/tour-dates-background-mobile.jpg);
        }
        img {
            width: 250px;
            display: block;
        }
    }
    
    .bit-logo-container {
        display: none;
    }
    
    .bit-button:focus {
        outline: 2px solid $headerIcons;
        outline-offset: 2px;
    }
    
    .bit-widget .bit-upcoming-events-show-all-button {
        background-color: #0e13cb !important;
    }