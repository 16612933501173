//COLOR VARIABLES
$black: #000000;
$white: #fff;
$background: #0d0aaa;
$header: #000000;
$headerIcons: #e3e1e2;
$headerIconsHover: #fa1400;
//BREAKPOINTS
@mixin breakpoint($point) {
    @if $point==xl {
        @media (min-width: 1500px) {
            @content ;
        }
    }
    @else if $point==lg {
        @media (max-width: 1400px) {
            @content ;
        }
    }
    @else if $point==premedium {
        @media (max-width: 1280px) {
            @content ;
        }
    }
    @else if $point==md {
        @media (max-width: 1199px) {
            @content ;
        }
    }
    @else if $point==presmall {
        @media (max-width: 1030px) {
            @content ;
        }
    }
    @else if $point==sm {
        @media (max-width: 992px) {
            @content ;
        }
    }
    @else if $point==xs {
        @media (max-width: 767px) {
            @content ;
        }
    }
    @else if $point==xxs {
        @media (max-width: 370px) {
            @content ;
        }
    }
}