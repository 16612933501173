.legal-navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    li {
        padding: 0 10px;
        border-right: 1px solid $white;
        &:last-child {
            border-right: none;
        }
    }
    a {
        color: $white;
        text-decoration: underline;
        line-height: 1;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

footer {
    margin-top: 220px;
    padding-bottom: 30px;
    @media(max-width: 700px) {
        margin-top: 75px;
        padding-bottom: 145px;
    }
    p,
    a {
        font-size: 1.5rem;
    }
}

.ontario-creates {
    margin: 0 auto;
    display: block;
}

.copyright {
    text-align: center;
    @media(max-width: 700px) {
        line-height: 2;
        max-width: 340px;
        margin-left: auto;
        margin-right: auto;
    }
}

.rights {
    @media(max-width: 1050px) {
        display: block;
    }
    @media(max-width: 700px) {
        display: inline;
    }
}